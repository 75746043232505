<template>
  <div class="pageview">
    <!-- banner切换 -->
    <van-swipe class="homebanner"
      :autoplay="3000"
      indicator-color="white">
      <van-swipe-item><img :src="require('../../assets/partymember/go2yd.jpg')" /></van-swipe-item>
      <van-swipe-item><img :src="require('../../assets/partymember/dangjian.jpg')" /></van-swipe-item>
      <van-swipe-item><img :src="require('../../assets/partymember/etuanjian.jpg')" /></van-swipe-item>
    </van-swipe>

    <van-grid class="wktype"
      :border="false">
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/partymember/history')">
        <van-image class="iconimg"
          :src="require('../../assets/partymember/homet.png')"></van-image>
        <span class="wktext">党史教育</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/partymember/consult')">
        <van-image class="iconimg"
          :src="require('../../assets/partymember/indexc.png')"></van-image>
        <span class="wktext">党务资讯</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/partymember/activity')">
        <van-image class="iconimg"
          :src="require('../../assets/partymember/homej.png')"></van-image>
        <span class="wktext">党建活动</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/partymember/integral')">
        <van-image class="iconimg"
          :src="require('../../assets/partymember/homec.png')"></van-image>
        <span class="wktext">积分排行</span>
      </van-grid-item>
    </van-grid>

    <div class="djlink">
      <img :src="require('../../assets/partymember/dangjian100.jpg')"
        width="100%"
        alt="">
    </div>

    <div class="contentmsg">
      <div class="contentA">
        <img :src="require('../../assets/partymember/content.png')" />
        <span>通知</span>
        <span class="blue">公告</span>
        <div @click="gotoUrl('/partymember/message')"
          class="newsmore">查看全部</div>
      </div>
      <div class="information">
        <div class="center"
          v-for="item in List"
          :key="item.index">
          <div @click="gotoUrl('/partymember/message/' + item.RIId)">
            <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
            <div class="Content">
              <div style="display: inline-block"
                class="ContentA">
                <img v-if="item.ThematicUrl"
                  :src="item.ThematicUrl"
                  alt="" />
              </div>
              <div style="display: inline-block"
                class="ContentB">
                <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                <br />
                <span class="centertime">{{ item.IssTime }}</span>
              </div>
            </div>
          </div>
        </div>
        <van-empty description="暂无通知"
          v-if="!List.length" />
      </div>
    </div>
  </div>
</template>

<script>
import { WeGetPartyInfoPage } from "@/api/RealInfo";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data () {
    return {
      List: [],
    };
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
    this.getPartyInfoList();
  },
  methods: {
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 通知消息
    getPartyInfoList () {
      WeGetPartyInfoPage({ riKind: 1, limit: 5 }).then((res) => {
        this.List = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.djlink {
  margin-top: 1.5vh;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  font-size: 0;
}
.contentA {
  width: 100%;
  overflow: hidden;
  line-height: 30px;
  padding: 1vh 1.5vh;
  box-sizing: border-box;
}
.contentA img {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
}
.contentA span {
  float: left;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
  line-height: 30px;
}
.contentA .newsmore {
  float: right;
  color: #777;
  font-size: 14px;
  line-height: 30px;
}
.contentA .blue {
  color: #477fff;
}
.pageview {
  background: #f6f6f6;
  min-height: 100%;
  padding: 1vh;
  box-sizing: border-box;
}
.homebanner img {
  width: 100%;
  height: 30vh;
  vertical-align: top;
}
.wktype {
  padding: 0;
  margin-top: 1.5vh;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.contentmsg {
  background: #fff;
  margin-top: 1.5vh;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
}
</style>